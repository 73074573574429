import React from 'react';
import '../../SCSS/MultiselectDropdown.scss'
import { Empty, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const MultiselectDropdown = ({ label, options, onChange, value, disabled = false, placeholder, loading = false }) => (
  <div className={`multiselect-wrap ${disabled ? 'disabled-field' : ''}`}>
    {label ? <label className='multiselect-label'>{label}</label> : null}
    <Select
      mode="multiple"
      style={{
        width: '100%',
      }}
      onChange={onChange}
      tokenSeparators={[';']}
      options={options}
      value={value ? value : []}
      disabled={disabled ? disabled : false}
      filterOption={(input, option) =>
        option?.label?.toLowerCase()?.includes(input?.toLowerCase())
      }
      showSearch
      autoClearSearchValue={false}
      placeholder={placeholder || ""}
      loading={loading}
      notFoundContent={loading ? <div className='multiselect-notfound'><LoadingOutlined style={{ fontSize: 28, color: '#2c0036', }} spin /></div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    />
  </div>

);
export default MultiselectDropdown;