import moment from 'moment'

export function sortByString(nestedFields) {
    return (a, b) => {
        const getField = (obj, fields) => {
            return fields.reduce((acc, field) => (acc && acc[field] !== undefined ? acc[field] : ""), obj);
        };
        const valueA = String(getField(a, nestedFields)).toUpperCase();
        const valueB = String(getField(b, nestedFields)).toUpperCase();
        if (valueA < valueB) { return -1 }
        if (valueA > valueB) { return 1 }
        return 0
    }
}

// Function to get the concatenated address string
function getConcatenatedAddress(obj) {
    const address1 = obj?.address1 ? String(obj?.address1).toUpperCase() : '';
    const address2 = obj?.address2 ? String(obj?.address2).toUpperCase() : '';
    return `${address1} ${address2}`.trim();
}

// Sorting function that uses the concatenated address string
export function sortByAddress() {
    return (a, b) => {
        const addressA = getConcatenatedAddress(a);
        const addressB = getConcatenatedAddress(b);

        if (addressA < addressB) { return -1; }
        if (addressA > addressB) { return 1; }
        return 0;
    };
}

export function sortByPhoneNumber() {
    return (a, b) => {
        const phoneA = a?.phone ? a?.phone : "";
        const phoneB = b?.phone ? b?.phone : "";

        if (phoneA < phoneB) { return -1; }
        if (phoneA > phoneB) { return 1; }
        return 0;
    };
}

export function sortByNumber(nestedFields) {
    return (a, b) => {
        const getField = (obj, fields) => {
            return fields.reduce((acc, field) => (acc && acc[field] !== undefined ? acc[field] : undefined), obj);
        };
        const valueA = Number(getField(a, nestedFields));
        const valueB = Number(getField(b, nestedFields));
        if (valueA < valueB) { return -1 }
        if (valueA > valueB) { return 1 }
        return 0
    }
}


export function sortByDate(nestedFields) {
    return (a, b) => {
        const getField = (obj, fields) => {
            return fields.reduce((acc, field) => (acc && acc[field] !== undefined ? acc[field] : undefined), obj);
        };
        const dateA = new Date(getField(a, nestedFields));
        const dateB = new Date(getField(b, nestedFields));
        if (dateA < dateB) { return -1 }
        if (dateA > dateB) { return 1 }
        return 0
    }
}

export function sortByConcatenatedString(dataArray, keysArray, order = 'asc') {
    return dataArray.sort((a, b) => {
        // const concatenateKeys = (obj, keys) => keys.map(key => obj[key] || '').join(' ')?.trim();
        const concatenateKeys = (obj, keys) => keys.map(key => (obj[key] || '').toString().toLowerCase()).join(' ')?.trim();

        const aValue = concatenateKeys(a, keysArray);
        const bValue = concatenateKeys(b, keysArray);

        let comparison = 0;

        if (aValue < bValue) {
            comparison = -1;
        } else if (aValue > bValue) {
            comparison = 1;
        }

        return order === 'desc' ? comparison * -1 : comparison;
    });
}

export function sortObjectsByAttribute(objects, attribute = "name") {
    if (objects) {
        return objects.sort((a, b) => {
            const valA = a[attribute]?.toString()?.toLowerCase();
            const valB = b[attribute]?.toString()?.toLowerCase();
            if (valA < valB) {
                return -1;
            }
            if (valA > valB) {
                return 1;
            }
            return 0;
        });
    } else {
        return []
    }
}

export function sortByDateTime(nestedFields) {
    return (a, b) => {
        const getField = (obj, fields) => {
            return fields.reduce((acc, field) => (acc && acc[field] !== undefined ? acc[field] : undefined), obj);
        };
        
        const parseDateTime = (dateTimeStr) => {
            return dateTimeStr ? moment(dateTimeStr).toDate() : null;
        };

        const dateTimeA = parseDateTime(getField(a, nestedFields));
        const dateTimeB = parseDateTime(getField(b, nestedFields));

        if (dateTimeA === null && dateTimeB === null) { return 0; }
        if (dateTimeA === null) { return -1; }
        if (dateTimeB === null) { return 1; } 

        if (dateTimeA < dateTimeB) { return -1; }
        if (dateTimeA > dateTimeB) { return 1; }
        return 0;
    };
}

export const sortString = (a, b) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    return a.toString().localeCompare(b.toString());
};

export const sortNumber = (a, b) => {
    const numA = parseFloat(a) || 0;
    const numB = parseFloat(b) || 0;
    return numA - numB;
};

export const sortDate = (a, b) => {
    if (!a && !b) return 0;
    if (!a) return -1;
    if (!b) return 1;
    return new Date(a) - new Date(b);
};