/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from "dayjs"
import 'dayjs/locale/en';

import Button from "../../commonModules/UI/Button";
import { fetchJobCodes, generateReport } from '../../../API/authCurd';
import { downloadFile, extractFilename } from '../../../Utils/helpers';
import { getLocalTimeZone, parseDateTimeString } from '../../../Utils/dateFormat';
import { sortObjectsByAttribute } from '../../../Utils/sortFunctions';
import MultiselectDropdown from '../../commonModules/UI/MultiselectDropdown';
import { jobStatuses } from '../../../Utils/staticdata';
import { MdDownload } from 'react-icons/md';
import { FaEye } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { jobStatusReportSchema, validateFormData } from '../../../Utils/validation';

const JobStatusReports = (props) => {
    const [customerList, setCustomerList] = useState([]);
    const [jobCodeList, setJobCodeList] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [formValues, setFormValues] = useState({ start_date: "", end_date: "" })
    const [selectedCustomer, setSelectedCustomer] = useState("")
    const [jobData, setJobData] = useState({ jobCodes: [], jobStatus: [] })
    const [loadingJobCodes, setLoadingJobCodes] = useState(false)
    const [formError, setFormError] = useState({})
    const navigate = useNavigate()

    // Start Date on change handler function
    const startdateOnChangeHandler = (value, datestring) => {
        let errors = formError
        if (errors.hasOwnProperty("start_date")) {
            delete errors["start_date"]
        }
        if (errors.hasOwnProperty("max_range_validation")) {
            delete errors["max_range_validation"]
        }
        setFormError(errors)
        setFormValues({ ...formValues, start_date: datestring })
    }

    // End Date on change handler function
    const enddateOnChangeHandler = (value, datestring) => {
        let errors = formError
        if (errors.hasOwnProperty("end_date")) {
            delete errors["end_date"]
        }
        if (errors.hasOwnProperty("max_range_validation")) {
            delete errors["max_range_validation"]
        }
        setFormError(errors)
        setFormValues({ ...formValues, end_date: datestring })
    }

    // Customer dropdown on change handler function
    const customerOnChangeHandler = (e) => {
        setSelectedCustomer(e.target.value)
    }

    // Generate report button click handler function
    const genrateReportBtnClickHandler = (isView = false) => {
        let tz = getLocalTimeZone()
        let data = {
            ...formValues,
            customer_id: selectedCustomer,
            start_date: formValues.start_date ? parseDateTimeString(formValues.start_date, 5) : "",
            end_date: formValues.end_date ? parseDateTimeString(formValues.end_date, 5) : "",
            job_codes: jobData?.jobCodes ? jobData?.jobCodes : [],
            status: jobData?.jobStatus ? jobData?.jobStatus : [],
            tz_name: tz.tz_name,
            tz_offset: tz.tz_offset,
            download_excel: isView ? 0 : 1
        }
        validateFormData(jobStatusReportSchema, data).then(() => {
        if (isView) {
            const state = {
                data: data,
                reportType: 'jobStatusReport',
                reportName: 'Job Status Report'
            }
            let url = `/view-report?state=${encodeURIComponent(JSON.stringify(state))}`
            window.open(url, '_blank', 'noopener,noreferrer')
        } else {
            setIsGenerating(true)
            generateReport("jobStatusReport", data).then((res) => {
                try {
                    const filename = extractFilename(res)
                    const blob = new Blob([res.data], { type: res.headers['content-type'] })
                    const downloadLink = window.URL.createObjectURL(blob)
                    downloadFile(downloadLink, filename)
                    setFormValues({ job_ids: [], start_date: "", end_date: "" })
                    setFormError({})
                } catch (error) {
                    console.log("jobStatusReport-error: ", error)
                }
            }).catch((err) => {
                console.log("jobStatusReport-err-2: ", err)
            }).finally(() => {
                setIsGenerating(false)
            })
        }
    }).catch((err) => {
        setFormError(err)
    })
    }

    useEffect(() => {
        if (props?.customers) {
            setCustomerList(sortObjectsByAttribute(props?.customers));
        } else {
            setCustomerList([]);
        }
    }, [props?.customers]);

    useEffect(() => {
        setFormError({})
        setSelectedCustomer("")
        setFormValues({ start_date: "", end_date: "" })
        setJobData({ jobCode: [], jobStatus: [] })
    }, [props.toggleState])

    const getJobCode = () => {
        setLoadingJobCodes(true)
        fetchJobCodes().then((res) => {
            setLoadingJobCodes(false)
            if (res.data?.job_codes) { setJobCodeList(sortObjectsByAttribute(res.data?.job_codes)) }
        }).catch(() => {
            setLoadingJobCodes(false)
            setJobCodeList([])
        })
    }

    useEffect(() => {
        getJobCode()
    }, []);

    const onChangeJobData = (data, field) => { setJobData((prev) => ({ ...prev, [field]: data })) }

    return (
        <div className="jobscode">
            <table>
                <tr>
                    <th>Job Status Report</th>
                </tr>
            </table>
            <div className='py-2 ps-3'>
                <div className='d-flex report-filter-wrap flex-column'>
                    <div className='d-flex row-style'>
                        <div className='date-range Column'>
                            {/* <div className='py-2'>
                                <b>Date Range </b>
                            </div> */}

                            <div className='d-flex align-items-start date-range-wrap'>
                                <div className='d-flex flex-column  date-range-picker'>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder='Start Date'
                                        name='start_date'
                                        onChange={startdateOnChangeHandler}
                                        value={formValues.start_date ? dayjs(formValues.start_date, "MM/DD/YYYY") : ""}
                                    />
                                    {formError?.start_date ? <span className='ms-1 text-danger'>{formError?.start_date}</span> : null}
                                </div>
                                <span className='align-self-center px-2'><b>-</b></span>
                                <div className='d-flex flex-column date-range-picker'>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder='End Date'
                                        name='end_date'
                                        onChange={enddateOnChangeHandler}
                                        value={formValues.end_date ? dayjs(formValues.end_date, "MM/DD/YYYY") : ""}
                                    />
                                    {formError?.end_date ? <span className='ms-1 text-danger'>{formError?.end_date}</span> : null}
                                </div>
                            </div>
                            {formError?.max_range_validation ? <span className='ms-1 text-danger'>{formError?.max_range_validation}</span> : null}
                        </div>
                        <div className='Column'>
                                <FloatingLabel label="Customers">
                                    <Form.Select
                                        aria-label="Customers"
                                        name='customer'
                                        value={selectedCustomer ?? ""}
                                        onChange={customerOnChangeHandler}
                                    >
                                        <option key={0} value="">Select Customer</option>
                                        {customerList.length > 0 && customerList.map((item) => {
                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                        })}
                                    </Form.Select>
                                </FloatingLabel>
                        </div>
                    </div>
                    <div className='d-flex row-style'>
                        <div className='report-job-status Column m-0'>
                            <p className='mb-0 label'>Job Codes </p>
                                <MultiselectDropdown
                                    options={jobCodeList.map((item) => { return { label: item.name, value: item.id } })}
                                    onChange={(value) => { onChangeJobData(value, 'jobCodes') }}
                                    value={jobData.jobCodes ? jobData.jobCodes : []}
                                    placeholder={'Search'}
                                    loading={loadingJobCodes ? true : false}
                                />
                        </div>
                        <div className='report-job-status Column me-0'>
                            <p className='mb-0 label'>Job Status </p>
                            <MultiselectDropdown
                                options={jobStatuses.map((item) => { return { label: item.name, value: item.id } })}
                                onChange={(value) => { onChangeJobData(value, 'jobStatus') }}
                                value={jobData.jobStatus ? jobData.jobStatus : []}
                                placeholder={'Search'}
                                loading={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-center my-2  w-100' style={{ bottom: "60px" }}>
                <Button onClick={() => genrateReportBtnClickHandler(true)}><FaEye /> View </Button>
                <Button className='ms-2' onClick={() => genrateReportBtnClickHandler(false)}><MdDownload /> Download {isGenerating && <Spin className="ms-2 text-white" indicator={<LoadingOutlined style={{ fontSize: 24, fill: '#fff' }} spin />} />}</Button>
            </div>
        </div>
    );
}

export default JobStatusReports;
