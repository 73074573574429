/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Table, Spin, Button, Tooltip, Tabs } from 'antd';
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import { useLocation, useSearchParams } from 'react-router-dom';
import { generateReport, viewReport } from '../../../API/authCurd';
import { parseDateTimeString } from '../../../Utils/dateFormat';
import { sortString, sortNumber, sortDate } from '../../../Utils/sortFunctions';

const dateField = ['due_date', 'task_completion_date', 'job_completion_date', 'desired_start_date',
    'desired_due_date', 'task_date', 'completion_date', 'last_login_date_time', 'last_active_date_time', 'last_note_date_time'];
const numericFields = ['hours_worked', 'hours_remaining', 'total_hours_worked', 'allocated_total_hours',
    'hours_logged', 'comment_count', 'task_total_hours'];

const ViewReport = () => {
    const [reportData, setReportData] = useState(null)
    const [state, setState] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const urlState = searchParams.get('state') ? JSON.parse(decodeURIComponent(searchParams.get('state'))) : location.state
        setState(urlState)
        const data = urlState ? urlState?.data : location.state?.data;
        const reportType = urlState ? urlState.reportType : location.state?.reportType;
        if (data && reportType) {
            fetchReportData(data, reportType);
        }
    }, [location, searchParams]);

    const fetchReportData = (data, reportType) => {
        setIsLoading(true);
        viewReport(reportType, { ...data, download_excel: 0 })
            .then((res) => {
                setReportData(res.data);
            })
            .catch((err) => {
                console.error("Error fetching report:", err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getColumns = (columnTitles, rowWiseColumnTitles) => {
        if (!columnTitles) return [];

        return columnTitles.map((title, index) => {
            let columnsSchema = {
                title: title,
                dataIndex: rowWiseColumnTitles[index],
                key: rowWiseColumnTitles[index],
                className: `${rowWiseColumnTitles[index]}`,
                ellipsis: true,
                render: (text, record) => {
                    if (dateField.includes(rowWiseColumnTitles[index])) {
                        return text ? parseDateTimeString(text, 6) : '-';
                    }
                    return text || '-';
                },
                sorter: (a, b) => {
                    const fieldName = rowWiseColumnTitles[index];
                    const valueA = a[fieldName];
                    const valueB = b[fieldName];

                    if (valueA === null || valueA === undefined) { return 1; }
                    if (valueB === null || valueB === undefined) { return -1; }
                    if (valueA === '') { return 1; }
                    if (valueB === '') { return -1; }

                    if (dateField.includes(fieldName)) { return sortDate(valueA, valueB); }
                    if (numericFields.some(field => fieldName.toLowerCase().includes(field))) { return sortNumber(valueA, valueB); }

                    return sortString(valueA, valueB);
                }
            }
            return columnsSchema;
        });
    };

    const renderTable = (columns, data) => (
        <div className='view-table-wrapper' style={{
            width: '100%',
            overflowX: 'auto'
        }}>
            <Table
                columns={columns}
                dataSource={data || []}
                scroll={{
                    y: 'calc(80vh - 150px)',
                    x: '100%'
                }}
                sticky={{
                    offsetHeader: 0,
                }}
                pagination={false}
            />
        </div>
    );

    const renderContent = () => {
        if (state?.reportType === 'jobTimeTrackingReport' && reportData) {
            const items = [
                {
                    key: '1',
                    label: 'IBMS Job Time Tracking Report',
                    children: renderTable(
                        getColumns(reportData.column_titles, reportData.row_wise_column_titles),
                        reportData.data
                    ),
                },
                {
                    key: '2',
                    label: 'Upcoming Job Events',
                    children: renderTable(
                        getColumns(reportData.upcomming_jobs_column_title, reportData.upcomming_jobs_row_wise_column_titles),
                        reportData.upcomming_job_data
                    ),
                },
            ];

            return (
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    className="w-100"
                />
            );
        } else if (state?.reportType === 'specialistWorkload' && reportData) {
            // Group data by user_id
            const userGroups = {};
            reportData.data.forEach(item => {
                if (!userGroups[item.user_id]) {
                    userGroups[item.user_id] = {
                        userData: [],
                        userName: item.user_name
                    };
                }
                userGroups[item.user_id].userData.push(item);
            });

            // Create tabs starting with Overview
            const items = [
                {
                    key: 'overview',
                    label: 'Overview',
                    children: renderTable(
                        getColumns(reportData.dashboard_column_title, reportData.dashboard_row_wise_column_titles),
                        reportData.dashboard_data
                    ),
                },
                // Add user-specific tabs
                ...Object.entries(userGroups).map(([userId, { userData, userName }]) => ({
                    key: userId,
                    label: userName || `User ${userId}`,
                    children: renderTable(
                        getColumns(reportData.column_titles, reportData.row_wise_column_titles),
                        userData
                    ),
                }))
            ];

            return (
                <Tabs
                    defaultActiveKey="overview"
                    items={items}
                    className="w-100"
                />
            );
        }

        // Default single table view
        return renderTable(
            getColumns(reportData?.column_titles, reportData?.row_wise_column_titles),
            reportData?.data
        );
    };

    const handleDownload = () => {
        if (!state?.data || !state?.reportType) return;

        setIsDownloading(true);
        generateReport(state.reportType, { ...state.data, download_excel: 1 })
            .then((res) => {
                try {
                    const filename = res.headers['content-disposition']
                        ?.split('filename=')[1]
                        ?.replace(/['"]/g, '') || 'report.xlsx';

                    const blob = new Blob([res.data], { type: res.headers['content-type'] });
                    const downloadLink = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = downloadLink;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(downloadLink);
                } catch (error) {
                    console.error("Download error:", error);
                }
            })
            .catch((err) => {
                console.error("Download error:", err);
            })
            .finally(() => {
                setIsDownloading(false);
            });
    };

    if (isLoading) {
        return (
            <div className="full-page-loader">
                <Spin indicator={<LoadingOutlined spin />} />
            </div>
        );
    }

    return (
        <div className="custTable view-report-page p-3" style={{
            minHeight: '80vh',
            maxWidth: '100%',
            overflow: 'hidden'
        }}>
            <div className="d-flex justify-content-between align-items-center mb-0">
                <h5 className="m-0 fw-semibold">{state?.reportName || 'Report'}</h5>
                <Tooltip title="Download Report">
                    <Button
                        icon={<DownloadOutlined />}
                        onClick={handleDownload}
                        loading={isDownloading}
                        type="primary"
                        size='large'
                        shape='circle'
                    />
                </Tooltip>
            </div>
            {(state?.data?.start_date && state?.data?.end_date) && <div className="date-range-view mb-3">
                <h6>Date Range Selected: {parseDateTimeString(state?.data?.start_date, 17)} - {parseDateTimeString(state?.data?.end_date, 17)}</h6>
            </div>}
            {renderContent()}
        </div>
    );
};

export default ViewReport;